<template>
	<div class="grid">
		<div class="col-12">
			<div v-if="pageLoading">
				<div class="custom-skeleton">
					<div class="card">
                        <div class="flex mb-3">
                            <Skeleton height="8rem" class="mb-1"></Skeleton>
                        </div>
                        <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
                    </div>
				</div>
			</div>
			<div v-else class="card p-2">
				<Toast/>
				<div class="surface-ground border-1 surface-border p-2">
					<div class="flex flex-grow-1 align-items-center justify-content-center my-2">
						<div class="flex flex-grow-1 align-items-center justify-content-start">
							<div class="text-xl font-semibold">Coupon List</div>
						</div>
						<div class="flex flex-grow-1 align-items-center justify-content-end">
							<Button label="Create New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
						</div>
					</div>
					<Divider></Divider>
					<div class="grid">
						<div class="col-12 lg:col-3 md:col-4">
							<div class="field" >
								<label for="keyword">Keyword</label>
								<InputText
									class="w-full"
									id="keyword"
									v-model="keywordListValue"
									placeholder="Search name or code"
								/>
							</div>
						</div>
						<div class="col-12 lg:col-3 md:col-4">
							<div class="field">
								<p class="mb-1">Discount</p>
								<InputText class="w-full" readonly :modelValue="discountRange"/>								
								<Slider
									class="w-full"
									id="discount"
									v-model="discountRange"
									:range="true"
								/>
							</div>
						</div>
						<div class="col-12 lg:col-3 md:col-4">
							<div class="field">
								<label for="valid_at">Coupon Valid At</label>
								<Calendar
									class="w-full"
									id="valid_at"
									v-model="validAt"
									dateFormat="dd-mm-yy"
									:showButtonBar="true"
									selectionMode="single"
									:showIcon="true"
									:showTime="true"
									:showSeconds="true"
									placeholder="Select a date"
								/>
							</div>
						</div>
						<div class="col-12 lg:col-3 lg:my-5">
							<div class="flex flex-wrap ">
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Clear" icon="pi pi-filter-slash" class="p-button-raised p-button-text w-full" @click="clearFilters"></Button>
								</div>
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Apply" icon="pi pi-filter" class="p-button-raised p-button-text w-full" @click="getFilteredTourList"></Button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<DataTable v-if="coupontList.data != ''" ref="dt" :value="coupontList.data" dataKey="id" responsiveLayout="scroll">
					<Column field="id" header="ID" headerStyle="width:8%; min-width:4rem;">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							#00{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="name" header="Name" headerStyle="width:14%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="code" header="Code" headerStyle="width:10%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Code</span>
							{{formatCurrency(slotProps.data.code)}}
						</template>
					</Column>
					<Column field="agency" header="Agency" headerStyle="width:14%; min-width:8rem;">
						<template #body="slotProps">
							{{slotProps.data.agency.name}}
						</template>
					</Column>
					<Column field="discount" header="Discount" headerStyle="width:12%; min-width:6rem;">
						<template #body="slotProps">
							<span class="p-column-title">Discount</span>
							{{slotProps.data.discount}}%
						</template>
					</Column>
					<Column field="valid_from" header="Valid From" headerStyle="width:15%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Valid From</span>
							{{formatHomeDate(slotProps.data.valid_from)}}
						</template>
					</Column>
					<Column field="valid_to" header="Valid To" headerStyle="width:15%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Valid To</span>
							{{formatHomeDate(slotProps.data.valid_to)}}
						</template>
					</Column>
					<Column header="Actions" headerStyle="width:12%; min-width:11rem;">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-raised p-button-text p-button-rounded mr-2" @click="confirmDetailSelected(slotProps.data)" />
							<Button icon="pi pi-pencil" class="p-button-raised p-button-text p-button-rounded mr-2" @click="editCouponOpen(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-raised p-button-text p-button-rounded p-button-danger mt-2" @click="confirmDeleteCoupon(slotProps.data)" />
						</template>
					</Column>
					<template #footer>
						<Paginator v-model:first="coupontList.from" :rows="paginationValue" :totalRecords="coupontList.total" :rowsPerPageOptions="[10,20,50]" @page="onPage" >
						</Paginator>
					</template>
				</DataTable>

				<template v-else>
					<div class="card">
						<div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 200px">
							<div class="flex align-items-center justify-content-center">
								<Message severity="info" :closable="false">No coupon found!</Message>
							</div>
						</div>
					</div>
				</template>

				<Dialog v-model:visible="itemDialog" :style="{width: '550px'}" :header="Header" :modal="true" class="p-fluid" :closable="false">
					<div class="field">
						<label for="name">Name</label>
						<InputText
							id="name"
							v-model="valid$.name.$model"
							required="true"
							autofocus 
							:class="{'p-invalid': valid$.name.$invalid && submitted}"
						/>
						<span v-if="valid$.name.$error && submitted">
							<span
								id="name-error"
								v-for="(error, index) of valid$.name.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Name")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.name.$invalid && submitted) || valid$.name.$pending.$response"
							class="p-error"
						>
							{{ valid$.name.required.$message.replace("Value", "Name") }}
						</small>
					</div>

					<div class="field">
						<label for="agency_id">Agency</label>
						<Dropdown
							id="agency_id"
							v-model="valid$.agency_id.$model"
							:options="agencyList"
							optionLabel="name"
							optionValue="id"
							:filter="true"
							placeholder="Select an agency"
							:class="{'p-invalid': valid$.agency_id.$invalid && submitted}"
						/>
						<span v-if="valid$.agency_id.$error && submitted">
							<span
								id="agency_id-error"
								v-for="(error, index) of valid$.agency_id.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Agency ID")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.agency_id.$invalid && submitted) || valid$.agency_id.$pending.$response"
							class="p-error"
						>
							{{ valid$.agency_id.required.$message.replace("Value", "Agency ID") }}
						</small>
					</div>

					<div class="field">
						<label for="code">Code</label>
						<InputText
							id="code"
							v-model="valid$.code.$model"
							required="true"
							autofocus
							:class="{'p-invalid': valid$.code.$invalid && submitted}"
						/>
						<span v-if="valid$.code.$error && submitted">
							<span
								id="code-error"
								v-for="(error, index) of valid$.code.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Code")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.code.$invalid && submitted) || valid$.code.$pending.$response"
							class="p-error"
						>
							{{ valid$.code.required.$message.replace("Value", "Code") }}
						</small>
					</div>
					
					<div class="field">
						<label for="discount">Discount (%)</label>
						<InputNumber
							:class="{'p-invalid': valid$.discount.$invalid && submitted}"
							id="discount"
							v-model="valid$.discount.$model"
							showButtons
							:min="1"
							:max="100"
						/>
						<span v-if="valid$.discount.$error && submitted">
							<span
								id="discount-error"
								v-for="(error, index) of valid$.discount.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Discount")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.discount.$invalid && submitted) || valid$.discount.$pending.$response"
							class="p-error"
						>
							{{ valid$.discount.required.$message.replace("Value", "Discount") }}
						</small>
					</div>

					<div class="field">
						<label for="threshold">Threshold</label>
						<InputNumber
							:class="{'p-invalid': valid$.threshold.$invalid && submitted}"
							id="threshold"
							v-model="valid$.threshold.$model"
							showButtons
							:min="0"
						/>
						<span v-if="valid$.threshold.$error && submitted">
							<span
								id="threshold-error"
								v-for="(error, index) of valid$.threshold.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Threshold")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.threshold.$invalid && submitted) || valid$.threshold.$pending.$response"
							class="p-error"
						>
							{{ valid$.threshold.required.$message.replace("Value", "Threshold") }}
						</small>
					</div>

					<div class="field">
						<label for="limit">Limit</label>
						<InputNumber
							:class="{'p-invalid': valid$.limit.$invalid && submitted}"
							id="limit"
							v-model="valid$.limit.$model"
							showButtons
							:min="0"
						/>
						<span v-if="valid$.limit.$error && submitted">
							<span
								id="limit-error"
								v-for="(error, index) of valid$.limit.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Limit")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.limit.$invalid && submitted) || valid$.limit.$pending.$response"
							class="p-error"
						>
							{{ valid$.limit.required.$message.replace("Value", "Limit") }}
						</small>
					</div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="valid_from">Valid From</label>
							<Calendar
								id="valid_from"
								v-model="valid$.valid_from.$model"
								dateFormat="dd-mm-yy"
								:showButtonBar="true"
								selectionMode="single"
								:showIcon="true"
								:showTime="true"
								:showSeconds="true"
								hourFormat="12"
								:class="{'p-invalid': valid$.valid_from.$invalid && submitted}"
							/>
							<span v-if="valid$.valid_from.$error && submitted">
								<span
									id="valid_from-error"
									v-for="(error, index) of valid$.valid_from.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Valid From")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.valid_from.$invalid && submitted) || valid$.valid_from.$pending.$response"
								class="p-error"
							>
								{{ valid$.valid_from.required.$message.replace("Value", "Valid From") }}
							</small>
						</div>
						<div class="field col">
							<label for="valid_to">Valid To</label>
							<Calendar
								id="valid_to"
								v-model="valid$.valid_to.$model"
								dateFormat="dd-mm-yy"
								:showButtonBar="true"
								selectionMode="single"
								:showIcon="true"
								:showTime="true"
								:showSeconds="true"
								hourFormat="12"
								:class="{'p-invalid': valid$.valid_to.$invalid && submitted}"
							/>
							<span v-if="valid$.valid_to.$error && submitted">
								<span
									id="valid_to-error"
									v-for="(error, index) of valid$.valid_to.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Valid To")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.valid_to.$invalid && submitted) || valid$.valid_to.$pending.$response"
								class="p-error"
							>
								{{ valid$.valid_to.required.$message.replace("Value", "Valid To") }}
							</small>
						</div>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button v-if="selectedItem != null" label="Update" icon="pi pi-check" class="p-button p-button-raised" @click="editCoupon(!valid$.$invalid)" :loading="loading"/>
						<Button v-else label="Create" icon="pi pi-check" class="p-button p-button-raised" @click="saveCoupon(!valid$.$invalid)" :loading="loading"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteItemDialog" :style="{width: '550px'}" header="Confirm" :modal="true" :closable="false">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="selectedItem">Are you sure you want to delete <b>{{selectedItem.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteItemDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-raised p-button-danger" @click="deleteCoupon" />
					</template>
				</Dialog>

				<Dialog v-model:visible="closeDetailDialog" :style="{width: '550px'}" header="Coupon Details" :modal="true" :closable="false">
					<div class="surface-section">
						<ul class="list-none p-0 m-0">
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">ID</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">#00{{selectedItem.id}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Name</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.name}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Agency ID</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.agency_id}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Agency Name</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.agency.name}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Code</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.code}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Discount</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.discount}}%</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Threshold</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{formatCurrency(selectedItem.threshold)}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Limit</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.limit}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Valid From</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{formatDate(selectedItem.valid_from)}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Valid To</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{formatDate(selectedItem.valid_to)}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Created At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{formatDate(selectedItem.created_at)}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Updated At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{formatDate(selectedItem.updated_at)}}</div>
							</li>
						</ul>
					</div>
					<template #footer>
						<Button label="Close" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import { reactive, ref } from 'vue';
import Axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, minValue, maxValue, numeric } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';

export default {
	setup() {
		const loading = ref(false);
		const pageLoading = ref(true);
		const coupontList = ref(null);
		const agencyList = ref(null);

		const itemDialog = ref(false);
		const deleteItemDialog = ref(false);
		const closeDetailDialog = ref(false);

		const selectedItem = ref(null);
		const filters = ref({});
		const submitted = ref(false);
		const keywordList = ref([]);
		const keywordListValue = ref();
		const discountRange = ref([0,100]);
		const validAt = ref();
		const paginationValue = ref(10);
		const pageValue = ref();
		const Header = ref('Create Coupon');

		const toast = useToast();
		const router = useRouter();
		const route = useRoute();

		const state = reactive({
			name: '',
			agency_id: '',
			code: '',
			discount: 0,
			threshold: 0,
			limit: 0,
			valid_from: '',
			valid_to: '',
		});

		const rules = {
			name: {required, maxLength: maxLength(100), minLength: minLength(4)},
			agency_id: {},
			code: {required, maxLength: maxLength(32), minLength: minLength(3)},
			discount: {required, numeric, maxValue: maxValue(100), minValue: minValue(1)},
			threshold: {required, numeric, minValue: minValue(1)},
			limit: {required, numeric, minValue: minValue(1)},
			valid_from: {required},
			valid_to: {required},
		};

		const valid$ = useVuelidate (rules, state);

		const getActiveQuerys = () => {
            if(route.query.page != null){
                pageValue.value = route.query.page;
            }
            if(route.query.pagination != null){
                paginationValue.value = parseInt(route.query.pagination);
            }
            if(route.query.keyword != null){
                keywordListValue.value = route.query.keyword;
            }
            if(route.query.discount != null){
                discountRange.value = route.query.discount;
            }
            if(route.query.validAt != null){
                validAt.value = new Date(route.query.validAt);
            }
        };
        getActiveQuerys();

		const getCouponlist = () =>{
			Axios
			.get("api/admin/coupon/list",{
				params: {
					page: pageValue.value,
                    pagination: paginationValue.value,
                    keyword: keywordListValue.value,
					discount: discountRange.value,
					validAt: validAt.value == null ? null : moment(validAt.value).format("YYYY-MM-DD HH:mm:ss"),
                }
			})
			.then((res) =>{
				console.log(res);
				pageLoading.value = false;
				if (res.data.response == "success") {
					coupontList.value = res.data.data;
					coupontList.value.from = coupontList.value.from-1;
				}
				else{
					console.log(res.data.msg);
				}
			})
			.catch((err) =>{
				console.log(err);
				pageLoading.value = false;
			})
		};
		getCouponlist();

		const getAgencylist = () =>{
			Axios
			.get("api/admin/agency/dropdown-list")
			.then((res) =>{
				if (res.data.response == "success") {
					agencyList.value = res.data.data;
				}
				else{
					console.log(res.data.msg);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getAgencylist();

		const showToast = (severity, summary, detail) => {
			toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
		};

		const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY hh:mm A");
        };

		const formatHomeDate = (rawDate) => {
            return moment(rawDate).format("DD-MM-YYYY hh:mm A");
        };

		const formatCurrency = (value) => {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'BDT'});
			return;
		};

		const openNew = () => {
			submitted.value = false;
			itemDialog.value = true;
		};

		const hideDialog = () => {
			itemDialog.value = false;
			submitted.value = false;
			closeDetailDialog.value = false;
			selectedItem.value = null;
            Header.value = 'Create Coupon';

			state.name = '';
			state.agency_id = '';
			state.code = '';
			state.discount = 0;
			state.threshold = 0;
			state.limit = 0;
			state.valid_from = '';
			state.valid_to = '';
		};

		//CREATE
		const saveCoupon = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }

			const formData1 = new FormData();

			formData1.append("name", state.name);
			if(state.agency_id)formData1.append("agency_id", state.agency_id);
			formData1.append("code", state.code);
			formData1.append("discount", state.discount);
			formData1.append("valid_from", moment(state.valid_from).format("YYYY-MM-DD HH:mm:ss"));
			formData1.append("valid_to", moment(state.valid_to).format("YYYY-MM-DD HH:mm:ss"));
			formData1.append("threshold", state.threshold);
			formData1.append("limit", state.limit);

			Axios
            .post("api/admin/coupon/create", formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
					itemDialog.value = false;
					getCouponlist();
					state.name = '';
					state.agency_id = '';
					state.code = '';
					state.discount = 0;
					state.threshold = 0;
					state.limit = 0;
					state.valid_from = '';
					state.valid_to = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                showToast("error", "Error", err);
            });
		};

		//READ
		const confirmDetailSelected = (product) => {
			selectedItem.value = product;
			closeDetailDialog.value = true;
		};
		
		//DELETE
		const confirmDeleteCoupon = (product) => {
			selectedItem.value = product;
			deleteItemDialog.value = true;
		};

		const deleteCoupon = () => {
			Axios
			.delete("api/admin/coupon/delete/"+selectedItem.value.id)
			.then(res => {
				if(res.data.response == "success"){
					showToast("success", "Success", res.data.message);
					getCouponlist();
					deleteItemDialog.value = false;
				}
				else{
					showToast("error", "Error", res.data.message);
				}
			})
			.catch(err => {
				console.log(err);
			})
		};

		//EDIT OR UPDATE
		const editCouponOpen = (product) => {
			selectedItem.value = product;
			itemDialog.value = true;
			Header.value = 'Edit Coupon';

			state.name = selectedItem.value.name;
			if(selectedItem.value.agency_id)state.agency_id = selectedItem.value.agency_id;
			state.code = selectedItem.value.code;
			state.discount = selectedItem.value.discount;
			state.threshold = selectedItem.value.threshold;
			state.limit = selectedItem.value.limit;
			state.valid_from = selectedItem.value.valid_from;
			state.valid_to = selectedItem.value.valid_to;
		};

		const editCoupon = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }

			const formData1 = new FormData();

			formData1.append("name", state.name);
			if(state.agency_id)formData1.append("agency_id", state.agency_id);
			formData1.append("code", state.code);
			formData1.append("discount", state.discount);
			formData1.append("valid_from", moment(state.valid_from).format("YYYY-MM-DD HH:mm:ss"));
			formData1.append("valid_to", moment(state.valid_to).format("YYYY-MM-DD HH:mm:ss"));
			formData1.append("threshold", state.threshold);
			formData1.append("limit", state.limit);

			formData1.append("_method", "PUT");

			Axios
            .post(`api/admin/coupon/update/${selectedItem.value.id}`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
                    selectedItem.value = null;
					itemDialog.value = false;
					getCouponlist();
					state.name = '';
					state.agency_id = '';
					state.code = '';
					state.discount = 0;
					state.threshold = 0;
					state.limit = 0;
					state.valid_from = '';
					state.valid_to = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                showToast("error", "Error", err);
                console.log(err);
            });
		};

		//FILTER
		const getFilteredTourList = () => {
            pageLoading.value = true;
            // showFilterMenu.value = false;

            var queryParams = {};
            if(keywordListValue.value != null){
                queryParams.keyword = keywordListValue.value;
            }
            if(discountRange.value != null){
                queryParams.discount = discountRange.value;
            }
            if(validAt.value != null){
                queryParams.validAt = moment(validAt.value).format("YYYY-MM-DD HH:mm:ss");
            }

            router.replace({ name: 'coupon', query: { ...queryParams } });

            getCouponlist();
        };

        const clearFilters = () => {
            pageLoading.value = true;
            // showFilterMenu.value = false;

            keywordListValue.value = null;
            discountRange.value = [0,100];
            validAt.value = null;
            router.replace();

            getCouponlist();
        };

		const onPage = (event) => {
			pageValue.value = event.page + 1;
			paginationValue.value = event.rows;
			pageLoading.value = true;
            // showFilterMenu.value = false;

            var queryParams = {};
            if(pageValue.value != null){
                queryParams.page = pageValue.value;
            }
            if(paginationValue.value != null){
                queryParams.pagination = paginationValue.value;
            }
            router.replace({ name: 'coupon', query: { ...queryParams } });

            getCouponlist();
		}

		return{
			pageLoading,
			valid$,
			itemDialog,
			deleteItemDialog,
			closeDetailDialog,
			selectedItem,
			filters,
			submitted,
			agencyList,
			coupontList,
			saveCoupon,
			loading,
			formatDate,
			formatHomeDate,
			formatCurrency,
			confirmDeleteCoupon,
			deleteCoupon,
			editCouponOpen,
			editCoupon,
			openNew,
			hideDialog,
			keywordList,
			keywordListValue,
			discountRange,
			validAt,
			clearFilters,
			getFilteredTourList,
			confirmDetailSelected,
			Header,
			paginationValue,
			onPage
		}
	},
}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';
</style>
